import store from "@/state/store.js";
import moment from "moment";
export default {
  data() {
    return {
      tableLoading: false,
      search: ""
    };
  },
  computed: {
    referralUser() {
      return store.getters["admin/referrals/getReferralDataUser"].filter(data => data.user.name.toLowerCase().includes(this.search.toLowerCase()) || data.user.phone == this.search);
    }
  },
  created() {
    this.tableLoading = true;
    store.dispatch("admin/referrals/getReferralUser", {
      referrer_id: this.$route.params.id
    }).then(data => {}).catch(() => {
      this.$message.error("Error fetching user referrals");
    }).finally(() => {
      this.tableLoading = false;
    });
  }
};